import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PaymentsApiService } from "@app/core/api/services/payments-api.service";



@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PaymentsApiService) private paymentService: PaymentsApiService
  ) {}

  clientID: string = 'AZlcAYm2oQZoYlK1IH384ZOiLUc0r1tqhhc0j-oslOD_wCJcQkFYVP2Qv2hyGQ191SswW0begTVYUT2i';
  userToken!: string;

  async getToken() {
    let token = await this.paymentService.GetUserIdToken({}).toPromise();
    // @ts-ignore
    this.userToken = !!token ? token.id_token : undefined;
  }

  public addScript(): Observable<void> {
    // @ts-ignore
    // let token = await this.paymentService.GetUserIdToken({}).toPromise().id_token;
    this.getToken();

    const paypalScriptElement: HTMLScriptElement = this.document.createElement('script');
    paypalScriptElement.src = `https://www.paypal.com/sdk/js?client-id=${this.clientID}`;
    paypalScriptElement.src += '&vault=true&disable-funding=venmo,paylater,card,credit';
    paypalScriptElement.setAttribute('data-user-id-token', this.userToken);
    paypalScriptElement.id = 'paypal-script';
    this.document.head.appendChild(paypalScriptElement);

    return fromEvent<void>(paypalScriptElement, 'load').pipe(first());
  }

  public removeScript() {
    const paypalScriptElement = this.document.getElementById('paypal-script');
    if (paypalScriptElement) this.document.head.removeChild(paypalScriptElement);
  }
}
