
export const environment = {
  production: false,
  commitHash: '7a8586d',
  commitDate: 'Fri Jan 24 14:08:28 2025 -0600',
  deployDate: '2025-01-24T20:08:54Z',
  apiUrl: 'http://localhost:8080'
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
