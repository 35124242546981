/**************************************************************************
 *                                                                        *
 *                 GENERATED FILE - DO NOT MODIFY!                        *
 *                                                                        *
 *                 Update API models in API project, then                 *
 *                 regenerate with the SwaggerToTypescript tool                    *
 *                                                                        *
 **************************************************************************/

import { CufiAdminApi } from "@app/core/api/base/CufiAdminApi";
import { PagedApiResponse } from "@app/core/api/base/PagedApiResponse";
import { ApiResponse } from "@app/core/api/base/ApiResponse";
import { Observable } from "rxjs";
import { Injectable, Inject, Optional, InjectionToken } from "@angular/core";
import { FormDto } from "@app/core/api/models/FormDto";
import { CreateForm } from "@app/core/api/models/CreateForm";
import { UpdateForm } from "@app/core/api/models/UpdateForm";
import { FormFieldTypeData } from "@app/core/api/models/FormFieldTypeData";
import { SaveFormResponseResponse } from "@app/core/api/models/SaveFormResponseResponse";
import { SaveFormResponse } from "@app/core/api/models/SaveFormResponse";
import { FormResponseDto } from "@app/core/api/models/FormResponseDto";

@Injectable({
  providedIn: "root",
})
export class FormsApiService {
  private api: CufiAdminApi;

  constructor(@Inject(CufiAdminApi) api: CufiAdminApi) {
    this.api = api;
  }

  public GetForms(
    request: GetFormsRequest,
  ): Observable<PagedApiResponse<Array<FormDto>>> {
    return this.api.GetApiCall<PagedApiResponse<Array<FormDto>>>(
      "/v1/Forms",
      200,
      request.path,
      request.query,
    );
  }

  public CreateForm(request: CreateFormRequest): Observable<FormDto> {
    return this.api.PostApiCall<FormDto, any>(
      "/v1/Forms",
      200,
      request.path,
      request.query,
      request.body,
    );
  }

  public GetForm(request: GetFormRequest): Observable<FormDto> {
    return this.api.GetApiCall<FormDto>(
      "/v1/Forms/{id}",
      200,
      request.path,
      request.query,
    );
  }

  public UpdateForm(request: UpdateFormRequest): Observable<FormDto> {
    return this.api.PostApiCall<FormDto, any>(
      "/v1/Forms/{id}",
      200,
      request.path,
      request.query,
      request.body,
    );
  }

  public DeleteForm(request: DeleteFormRequest): Observable<string> {
    return this.api.DeleteApiCallWithReturnType<string>(
      "/v1/Forms/{id}",
      204,
      request.path,
      request.query,
    );
  }

  public GetFormByVanity(request: GetFormByVanityRequest): Observable<FormDto> {
    return this.api.GetApiCall<FormDto>(
      "/v1/Forms/vanity/{slug}",
      200,
      request.path,
      request.query,
    );
  }

  public GetDefaultForm(request: GetDefaultFormRequest): Observable<FormDto> {
    return this.api.GetApiCall<FormDto>(
      "/v1/Forms/default",
      200,
      request.path,
      request.query,
    );
  }

  public GetPublishedForm(
    request: GetPublishedFormRequest,
  ): Observable<FormDto> {
    return this.api.GetApiCall<FormDto>(
      "/v1/Forms/{id}/published",
      200,
      request.path,
      request.query,
    );
  }

  public GetVanitySlugIsAvailable(
    request: GetVanitySlugIsAvailableRequest,
  ): Observable<boolean> {
    return this.api.GetApiCall<boolean>(
      "/v1/Forms/vanity/{slug}/isAvailable",
      200,
      request.path,
      request.query,
    );
  }

  public GetFormRevisions(
    request: GetFormRevisionsRequest,
  ): Observable<FormDto> {
    return this.api.GetApiCall<FormDto>(
      "/v1/Forms/{id}/revisions",
      200,
      request.path,
      request.query,
    );
  }

  public GetFormRevisionSummaries(
    request: GetFormRevisionSummariesRequest,
  ): Observable<FormDto> {
    return this.api.GetApiCall<FormDto>(
      "/v1/Forms/{id}/revisions/summary",
      200,
      request.path,
      request.query,
    );
  }

  public GetFormRevision(request: GetFormRevisionRequest): Observable<FormDto> {
    return this.api.GetApiCall<FormDto>(
      "/v1/Forms/{id}/revisions/{revisionId}",
      200,
      request.path,
      request.query,
    );
  }

  public GetFieldTypes(
    request: GetFieldTypesRequest,
  ): Observable<FormFieldTypeData> {
    return this.api.GetApiCall<FormFieldTypeData>(
      "/v1/Forms/schema",
      200,
      request.path,
      request.query,
    );
  }

  public SaveFormResponse(
    request: SaveFormResponseRequest,
  ): Observable<SaveFormResponseResponse> {
    return this.api.PostApiCall<SaveFormResponseResponse, any>(
      "/v1/Forms/{id}/revisions/{revisionId}/responses",
      200,
      request.path,
      request.query,
      request.body,
    );
  }

  public GetFormResponse(
    request: GetFormResponseRequest,
  ): Observable<FormResponseDto> {
    return this.api.GetApiCall<FormResponseDto>(
      "/v1/Forms/{formId}/revisions/{revisionId}/responses/{id}",
      200,
      request.path,
      request.query,
    );
  }
}

export interface GetFormsRequestQuery {
  active?: boolean;
  startBefore?: string;
  startAfter?: string;
  startOn?: string;
  endBefore?: string;
  endAfter?: string;
  endOn?: string;
  slug?: string;
  sortBy?: string[];
  search?: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface GetFormsRequest {
  path?: void;
  query: GetFormsRequestQuery;
  body?: void;
}

export interface CreateFormRequest {
  path?: void;
  query?: void;
  body: CreateForm;
}

export interface GetFormRequestPath {
  id: number;
}

export interface GetFormRequest {
  path: GetFormRequestPath;
  query?: void;
  body?: void;
}

export interface UpdateFormRequestPath {
  id: number;
}

export interface UpdateFormRequest {
  path: UpdateFormRequestPath;
  query?: void;
  body: UpdateForm;
}

export interface DeleteFormRequestPath {
  id: number;
}

export interface DeleteFormRequest {
  path: DeleteFormRequestPath;
  query?: void;
  body?: void;
}

export interface GetFormByVanityRequestPath {
  slug: string;
}

export interface GetFormByVanityRequest {
  path: GetFormByVanityRequestPath;
  query?: void;
  body?: void;
}

export interface GetDefaultFormRequest {
  path?: void;
  query?: void;
  body?: void;
}

export interface GetPublishedFormRequestPath {
  id: number;
}

export interface GetPublishedFormRequest {
  path: GetPublishedFormRequestPath;
  query?: void;
  body?: void;
}

export interface GetVanitySlugIsAvailableRequestPath {
  slug: string;
}

export interface GetVanitySlugIsAvailableRequestQuery {
  formId?: number;
}

export interface GetVanitySlugIsAvailableRequest {
  path: GetVanitySlugIsAvailableRequestPath;
  query: GetVanitySlugIsAvailableRequestQuery;
  body?: void;
}

export interface GetFormRevisionsRequestPath {
  id: number;
}

export interface GetFormRevisionsRequest {
  path: GetFormRevisionsRequestPath;
  query?: void;
  body?: void;
}

export interface GetFormRevisionSummariesRequestPath {
  id: number;
}

export interface GetFormRevisionSummariesRequest {
  path: GetFormRevisionSummariesRequestPath;
  query?: void;
  body?: void;
}

export interface GetFormRevisionRequestPath {
  id: number;
  revisionId: number;
}

export interface GetFormRevisionRequest {
  path: GetFormRevisionRequestPath;
  query?: void;
  body?: void;
}

export interface GetFieldTypesRequest {
  path?: void;
  query?: void;
  body?: void;
}

export interface SaveFormResponseRequestPath {
  id: number;
  revisionId: number;
}

export interface SaveFormResponseRequest {
  path: SaveFormResponseRequestPath;
  query?: void;
  body: SaveFormResponse;
}

export interface GetFormResponseRequestPath {
  id: number;
  formId: string;
  revisionId: string;
}

export interface GetFormResponseRequest {
  path: GetFormResponseRequestPath;
  query?: void;
  body?: void;
}
